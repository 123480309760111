'use strict';

var _ = require('lodash'),
    ajax = require('../../ajax'),
    page = require('../../page'),
    loading = require('../../loading'),
    Promise = require('promise'),
    config = require('../../../scripts/config'),
    util = require('../../util'),
    modal = require('../../modal');

var PdpServer = {
    /** current product id */
    pid: null,
    productSets: [],
    qty: 1,
    salePrice: 0,
    webPrice: 0,

    init: function (pid) {
        this.pid = pid;
        this.productSets = [];
        this.qty = parseInt($("#quantity").val(), 10);
        this.salePrice = parseInt($("#salePrice").attr('data-price'), 10);
        this.webPrice = parseInt($("#webPrice").attr('data-price'), 10);
    },
    productToCart: function () {
        var self = this;
        this.productSets = [];
        $("select.product-set-item-select").each(function(i) {
            var selectedOpt =  $(this).find(":selected");
            if ($(selectedOpt).val() != '') {
                var pItem = {
                    psid: $(this).attr('psid'),
                    pid: selectedOpt.val()
                }
                self.addProduct(pItem, true);
            }
        });
    },
    /** add the product to cart
     * @param pItem product info
     * @param: isRemove boolean
     */
    addProduct: function (pItem, isRemove) {
        var self = this;
        if (isRemove === true) {
            self.removeProduct(pItem);
            this.productSets = _.reject(this.productSets, function(item) {
                return item.psid == pItem.psid;
            });
        }

        this.productSets.push(pItem);
        //check show price after change
        //console.log(JSON.stringify(this.productSets));
    },
    /** update price the product on customization
     * @param pItem product info
     */
    changeQuantity: function (qty) {
        qty = parseInt(qty, 10);
        if (!qty) {
            qty = 1;
        }
        this.qty = qty;
        //this.showPrice();
        return this;
    },
    /** remove the product on customization
     * @param pItem product info
     */
    removeProduct: function (pItem) {
        var self = this;
        var foundItem = _.find(this.productSets, function(item) {
            return item.psid == pItem.psid;
        });
    },
    addItemToCart: function (form) {
        var self = this;
        var $form = $(form),
        $qty = $form.find('select[name="Quantity"] option:selected');
        if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
            $qty.val('1');
        }
        this.productToCart();
        $('#bundledProductSets').val(JSON.stringify(self.productSets));
        return this.sendRequest(Urls.addProduct, $form.serialize());
    },
    sendRequest: function (url, data) {
        var self = this;
        
        return new Promise(function (resolve) {
            ajax.load({
                type: 'POST',
                url: url,
                data: data,
                callback: function (response, textStatus) {
                    //console.log(response, textStatus);
                    resolve(response);
                }
            });
        });
    },
    addToCart: function ($form) {
        //var self = this;
        this.addItemToCart($form)
        .then(function (response) {
            //console.log(response);
        	if(response.result) {
                if(response.result.statusCode 
                    && !isNaN(response.result.statusCode)) {
                    page.redirectError(response.result.statusCode);
                }else {
                    alert(response.result.message);
                    loading.hide();
                }
        	} else {
        		page.redirect(Urls.cartShow);
        	}
        });
    },
    displayPrice: function () {
        // var self = this;
        var webPrice = 0;
        var salePrice = 0;
        var qty = 0;
        var qtyValue = $("#quantity").val();
        if(qtyValue == null || qtyValue == undefined || qtyValue == "") {
        	qty = 0;
        } else {
        	qty = parseInt(qtyValue, 10);
        }
        
        //get PC price
        var fixedWebprice = parseInt($("#fixed-webprice").val(), 10);
        var fixedSaleprice = parseInt($("#fixed-saleprice").val(), 10);
        webPrice += fixedWebprice;
        salePrice += fixedSaleprice;
        
        $('select.product-set-item-select').each(function (e) {
            if($('option', this).is(':selected') === true) {
                var control = $('option:selected', this);
                if(control.attr('data-price') != undefined ) {
                    webPrice += parseInt(control.attr('data-price'), 10);
                }
                if(control.attr('data-saleprice') && control.attr('data-saleprice') != undefined) {
                    salePrice += parseInt(control.attr('data-saleprice'), 10);
                }
            }
        });

        webPrice = (webPrice * qty);
        salePrice = (salePrice * qty);

        this.showPrice(webPrice, salePrice);
    },
    /** update price the product on customization
     * @param pItem product info
     */
    showPrice: function (webPrice, salePrice) {
        
        var newWebPrice = webPrice.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1"+ config.CurrencySeparate);
        $("#webPrice").html(newWebPrice + config.CurrencySymbol);

        var newSalePrice = salePrice.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1"+ config.CurrencySeparate);
        $("#salePrice").html(newSalePrice + "<span>" + config.CurrencySymbol + "</span>");
        $("#salePrice-mobile").html(newSalePrice + config.CurrencySymbol);
        $("body").trigger("price:updated");
    }
};

module.exports = PdpServer;
