/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://github.com/SalesforceCommerceCloud/sitegenesis
 */

'use strict';

var page = require('./page'),
    modal = require('./modal'),
    loading = require('./loading');

function initializeEvents() {
    $('.buziness_confirm_b2c').on('click', function (e) {
        e.preventDefault();
        //var _this = this;
        modal.confirm(Resources.MODAL_BUSINESS_TEXT, Resources.MODAL_BUSINESS_TEXT_SMALL, function() {
            // console.log('callback yes');
            loading.show();
            page.redirect(Urls.b2bSitedomain);
        });
    });
    
    $('.site-transition').on('click', function (e) {
        var path = $(this).attr('href');
        e.preventDefault();
        modal.confirm(Resources.MODAL_BUSINESS_TEXT, Resources.MODAL_BUSINESS_TEXT_SMALL, function() {
            loading.show();
            // FCCL-2401: Change url form b2cSiteHost into b2cSiteDomain
            page.redirect(Urls.b2bSitedomain + path);
        });
    });

    // FCCL-2624 カートクリアチェック
    $('.sourcecode-list div ul li a').on('click', function (e) {
        var sourceCodeUrl = $(this).attr('href');
        var logout = '&logout=yes';
        var cartClear = '&cartClear=yes';
        e.preventDefault();
        // FCCL-2624 IE11対応
        $.ajax({
            type: "POST",
            url: $(location).attr('href').replace('SourceCode-Show', 'SourceCode-CheckCart')
        }).then(function (response) {
            if (response.result) {
                modal.confirm(
                    Resources.MODAL_CART_CLEAR_TEXT,
                    '',
                    function(){
                        window.open(sourceCodeUrl + logout + cartClear, '_blank');
                    },
                    function(){
                        window.open(sourceCodeUrl, '_blank');
                    });
            } else {
                window.open(sourceCodeUrl + logout + cartClear, '_blank');
            }
        })
        .catch(function () {
            window.open(sourceCodeUrl + logout + cartClear, '_blank');
        });
    });

    // FCCL-3139
    $('input[name="sendEmployeeEmail"]').on('input', function (e) {
        var re = new RegExp($('input[name="regexp"]').val(), 'ig');
        if ($(this).val() && re.test($(this).val())) {
            $('#send-employee-email').prop("disabled", false);
        } else {
            $('#send-employee-email').prop("disabled", true);
        }
    });
    $('input[name="sendEmployeeEmail"]').on('focusout', function (e) {
        var re = new RegExp($('input[name="regexp"]').val(), 'ig');
        if ($(this).val() && !re.test($(this).val())) {
            $('#email-format-error').show();
        } else {
            $('#email-format-error').hide();
        }
    });
    $('#send-employee-email').on('click', function (e) {
        e.preventDefault();
        modal.confirm(Resources.SEND_EMPLOYEE_EMAIL, null, function() {
            loading.show();
            $.ajax({
                type: "POST",
                url: Urls.snedEmployeeEmail,
                data: {
                    email : $('input[name="sendEmployeeEmail"]').val()
                }
            }).then(function (response) {
                $('input[name="sendEmployeeEmail"]').val('');
                $('#send-ok').show();
                $('#send-employee-email').prop("disabled", true);
            });
            loading.hide();
        });
    });
}

var b2capp = {
    init: function () {
        initializeEvents();
    }
};

// initialize app
$(document).ready(function () {
    b2capp.init();
});
